import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ErrorType } from '../../constants/types';
import {
  formatErrorMessage,
  formatCatchError,
} from '../../utils/errorMessageUtils';
import SubmitCVService from '../../services/SubmitCV';

/* eslint-disable no-param-reassign */
export interface SubmitCVPayload {
  first_name: string;
  last_name: string;
  email: string;
}

export interface SubmitCVState {
  loading: boolean;
  payload: SubmitCVPayload | null;
  confirmedEmail: string;
  error: ErrorType | null | undefined;
}

const initialState: SubmitCVState = {
  loading: false,
  payload: null,
  confirmedEmail: '',
  error: null,
};

export const checkEmailAction = createAsyncThunk<
  string,
  SubmitCVPayload,
  {
    rejectValue: ErrorType;
  }
>(
  'submitCV/checkEmail',
  async (payload: SubmitCVPayload, { rejectWithValue }) => {
    try {
      const response = await SubmitCVService.doCheckEmail(payload);
      if (response && response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: formatErrorMessage(response),
      });
    } catch (err) {
      return rejectWithValue({
        message: formatCatchError(err),
      });
    }
  },
);

export const submitCVSlice = createSlice({
  name: 'submitCV',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkEmailAction.pending, (state, action) => {
        state.loading = true;
        state.payload = action.meta.arg;
      })
      .addCase(checkEmailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.confirmedEmail = action.payload;
      })
      .addCase(checkEmailAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      });
  },
});

export const selectLoading = (state: RootState) => state.submitCV.loading;
export const selectPayload = (state: RootState) => state.submitCV.payload;
export const selectConfirmedEmail = (state: RootState) =>
  state.submitCV.confirmedEmail;
export const selectError = (state: RootState) => state.submitCV.error;

export default submitCVSlice.reducer;
