import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  VideoInterviewAppointmentInfoState,
  ErrorType,
} from '../../constants/types';
import VideoInterviewService from '../../services/VideoInterview';
import {
  formatErrorMessage,
  formatCatchError,
} from '../../utils/errorMessageUtils';

/* eslint-disable no-param-reassign */
export interface VideoInterviewResponse {
  status: string;
  firstName: string;
  lastName: string;
  startTime: number;
}

export interface CreateZoom {
  link: string;
}
export interface VideoInterviewState {
  loading: boolean;
  info: VideoInterviewAppointmentInfoState | null;
  createZoom: CreateZoom | null;
  error: ErrorType | null | undefined;
  zoomCreating: boolean;
  zoomStatusChecking: boolean;
  zoomStatusError: ErrorType | null | undefined;
  zoomStatus: { opened: boolean } | null;
}

const initialState: VideoInterviewState = {
  loading: true,
  info: null,
  createZoom: {
    link: '',
  },
  error: null,
  zoomCreating: false,
  zoomStatusChecking: false,
  zoomStatusError: null,
  zoomStatus: null,
};

export const getVideoInterviewInfoAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterview/getVideoInterviewInfoAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await VideoInterviewService.doGetVideoInterviewInfo(
        payload,
      );
      if (response && response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: formatErrorMessage(response),
      });
    } catch (err: any) {
      return rejectWithValue({
        message: formatCatchError(err),
      });
    }
  },
);

export const creteVideoInterviewZoomAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterview/creteVideoInterviewZoomAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await VideoInterviewService.doCreateVideoInterviewZoom(
        payload,
      );
      if (response && response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: formatErrorMessage(response),
      });
    } catch (err: any) {
      return rejectWithValue({
        message: formatCatchError(err),
      });
    }
  },
);

export const getVideoInterviewZoomStatusAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoInterview/getVideoInterviewZoomStatusAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response =
        await VideoInterviewService.doGetVideoInterviewZoomStatus(payload);
      if (response && response.success) {
        return response.results;
      }
      return rejectWithValue(formatErrorMessage(response));
    } catch (err: any) {
      return rejectWithValue(formatCatchError(err));
    }
  },
);

export const videoInterviewSlice = createSlice({
  name: 'videoInterview',
  initialState,
  reducers: {
    clearAction: (state) => {
      state.loading = false;
      state.zoomCreating = false;
      state.error = null;
    },
    clearZoomStatusAction: (state) => {
      state.zoomStatusChecking = false;
      state.zoomStatusError = null;
      state.zoomStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideoInterviewInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoInterviewInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getVideoInterviewInfoAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(creteVideoInterviewZoomAction.pending, (state) => {
        state.zoomCreating = true;
      })
      .addCase(creteVideoInterviewZoomAction.fulfilled, (state, action) => {
        if (state.info) {
          state.info = {
            ...state.info,
            zoom_link: action.payload,
          };
        }
      })
      .addCase(creteVideoInterviewZoomAction.rejected, (state, action) => {
        state.zoomCreating = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(getVideoInterviewZoomStatusAction.pending, (state) => {
        state.zoomStatusChecking = true;
      })
      .addCase(getVideoInterviewZoomStatusAction.fulfilled, (state, action) => {
        const { total_records: totalRecords = 0 }: { total_records: number } =
          action.payload;
        const opened: boolean = totalRecords > 0;
        state.zoomStatusChecking = false;
        state.zoomStatus = { opened };
      })
      .addCase(getVideoInterviewZoomStatusAction.rejected, (state, action) => {
        state.zoomStatusChecking = false;
        if (action.payload) {
          state.zoomStatusError = action.payload;
        } else {
          state.zoomStatusError = action.error as ErrorType;
        }
      });
  },
});

export const { clearAction, clearZoomStatusAction } =
  videoInterviewSlice.actions;

export const selectLoading = (state: RootState) => state.videoInterview.loading;
export const selectInfo = (state: RootState) => state.videoInterview.info;
export const selectError = (state: RootState) => state.videoInterview.error;
export const selectZoomCreating = (state: RootState) =>
  state.videoInterview.zoomCreating;
export const selectZoomStatusChecking = (state: RootState) =>
  state.videoInterview.zoomStatusChecking;
export const selectZoomStatusError = (state: RootState) =>
  state.videoInterview.zoomStatusError;
export const selectZoomStatus = (state: RootState) =>
  state.videoInterview.zoomStatus;

export default videoInterviewSlice.reducer;
