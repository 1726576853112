/* eslint-disable complexity */
export const formatErrorMessage = (
  error: any,
  defaultMessage = 'Not Found',
) => {
  if (!error) {
    return defaultMessage;
  }
  if (typeof error === 'object') {
    if (error.message) {
      return error.message.en || error.message;
    }
    if (error.en) {
      return error.en;
    }
  }
  return error;
};

export const formatCatchError = (error: any, defaultMessage = 'Not Found') => {
  if (!error) {
    return defaultMessage;
  }
  if (typeof error === 'object') {
    if (error.response) {
      if (typeof error.response.data === 'object') {
        return error.response.statusText || defaultMessage;
      }
      return error.response.data || defaultMessage;
    }
    if (error.message) {
      return error.message;
    }
  }
  return error;
};
